import { TMaterialAmount, TWork } from '../../types/works'
import { useGetMaterialsQuery, useGetUnitsQuery } from '../../store/lists'
import { TableCell, TableRow } from '@mui/material'
import { MenuItemDelete, MoreVertMenu } from '../menu'
import * as React from 'react'
import { useState } from 'react'
import { MenuItemEditSingle } from '../menu/MenuItemEditSingle'
import { TUnit } from '../../types/lists'
import EditVolumeDialog from './EditVolumeDialog'
import { useDeleteMaterialAmountMutation } from '../../store/works'
import { toast } from 'react-toastify'
import { toastError } from '../../util/toastError'

type Props = {
  work: TWork
  ma: TMaterialAmount
  unit: TUnit
  done: number
}

const MaterialAmountRow = ({ work, ma, unit, done }: Props) => {
  const { data: materials } = useGetMaterialsQuery(work.building_id)
  const { data: units } = useGetUnitsQuery(work.building_id)
  const [deleteAmount] = useDeleteMaterialAmountMutation()

  const [menuOpen, setMenuOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)

  const handleEdit = () => {
    setEditOpen(true)
  }

  const handleDelete = () => {
    if (done > 0) {
      toast.error('Нельзя удалить объем. Так как он уже был использован в журналах.')
      return
    }
    deleteAmount({
      buildingId: work.building_id,
      body: {
        work_id: work.id,
        wma_id: ma.id,
      },
    })
      .unwrap()
      .catch(toastError)
  }

  return (
    <>
      <TableRow key={ma.id} hover>
        <TableCell>{materials && materials[ma.material_id]?.name}</TableCell>
        <TableCell>
          {ma.value} {unit && unit.name}
        </TableCell>
        <TableCell>
          {done} {units && units[ma.unit_id]?.name}
        </TableCell>
        <TableCell align="right">
          <MoreVertMenu
            open={menuOpen}
            onOpen={() => setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}
          >
            <MenuItemEditSingle onClick={handleEdit}>Изменить объем</MenuItemEditSingle>
            <MenuItemDelete onClick={handleDelete} disabled={done > 0}>
              Удалить
            </MenuItemDelete>
          </MoreVertMenu>
        </TableCell>
      </TableRow>
      <EditVolumeDialog
        work={work}
        ma={ma}
        min={done}
        unit={unit}
        onEdit={() => setEditOpen(false)}
        onClose={() => setEditOpen(false)}
        open={editOpen}
      />
    </>
  )
}

export { MaterialAmountRow }
