import {
  useAddThemeMutation,
  useGetProfileQuery,
  useRemoveCertificateMutation,
} from '../../store/profile'
import AlertError from '../alert/AlertError'
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import { formatDateTime } from '../../util/date'
import CertificateDialogForm from './CertificateDialogForm'
import IconButton from '@mui/material/IconButton'
import { Delete } from '@mui/icons-material'
import { EditDialog } from './EditDialog'
import { BaseContainer } from '../common/Container'
import ThemeSwitch from './ThemeSwitch'
import { useGetSettingsQuery, useSetSettingsMutation } from '../../store/settings'
import { SettingsForm } from './SettingsForm'
import { SettingsCard } from './SettingsCard'




export const ProfileView = () => {
  const { data: profile, isSuccess } = useGetProfileQuery()
  const [removeCertificate] = useRemoveCertificateMutation()
  const [setTheme] = useAddThemeMutation()



  if (!isSuccess || profile === null) {
    return <AlertError>Профиль не загружен</AlertError>
  }

  const removeHandler = (id: string) => {
    removeCertificate({ certificate_id: id })
  }

  const changeThemeHandler = (theme: string) => {
    setTheme({ theme })
  }

  return (
    <BaseContainer>
      <Stack direction="column" spacing={2}>
        <TableContainer component={Card}>
          <CardHeader title="Профиль" action={<EditDialog />} />
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>
                  {profile.last_name} {profile.first_name} {profile.middle_name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ИНН {profile.inn}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>№ специалиста {profile.specialist_id_number}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Card>
          <CardHeader title="Настройки" />
          <CardContent>
            <ThemeSwitch onChange={changeThemeHandler} value={profile.theme || 'light'} />
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="ЭЦП" subheader="Сертификаты" action={<CertificateDialogForm />} />
          <CardContent>
            {profile.certificates.length > 0 && (
              <Stack direction="column" spacing={2}>
                {profile.certificates.map((cert) => {
                  const isValid = new Date() < new Date(cert.period_to)
                  return (
                    <Alert
                      severity={isValid ? 'success' : 'error'}
                      key={cert.id}
                      action={
                        <IconButton title="Удалить" onClick={() => removeHandler(cert.id)}>
                          <Delete />
                        </IconButton>
                      }
                    >
                      <AlertTitle>
                        {cert.name} ({isValid ? 'действующий' : 'недействительный'})
                      </AlertTitle>
                      <Typography variant="subtitle1">
                        Срок действия: с <b>{formatDateTime(cert.period_from)}</b> по{' '}
                        <b>{formatDateTime(cert.period_to)}</b>
                      </Typography>
                      <Typography variant="subtitle2">Выдан: {cert.issuer_name}</Typography>
                      <Typography variant="caption">
                        Отпечаток: {cert.thumbprint}
                        <br />
                        SN: {cert.serial_number}
                      </Typography>
                    </Alert>
                  )
                })}
              </Stack>
            )}
            {profile.certificates.length === 0 && (
              <Alert severity={'warning'}>
                <AlertTitle>Отсутствуют сертификаты</AlertTitle>
                Для возможности подписания документов добавьте сертификат в
              </Alert>
            )}
          </CardContent>
        </Card>

        <SettingsCard />


      </Stack>
    </BaseContainer>
  )
}
