import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { IAisKsGk } from '../../types/aisks'


export const aisKsApi = createApi({
  reducerPath: 'aisks',
  tagTypes: ['AisKs'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    aisKsData: builder.query<IAisKsGk[], any>({
      query: ({ buildingId, params }) => ({
        url: `/buildings/${buildingId}/aisks/get-data`,
        method: 'GET',
        // params: params
      }),
      providesTags: ['AisKs'],
    }),
    aisKsQueue: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/aisks/queue`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['AisKs'],
    }),
    aisKsReload: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/aisks/reload`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['AisKs'],
    }),
    aisKsGetToken: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/aisks/token`,
        method: 'POST',
        body
      }),
    }),
    aisKsLink: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/aisks/link`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['AisKs'],
    }),
    aisKsLoadDocument: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/aisks/load-document`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['AisKs'],
    }),
  }),
})

export const {
  useAisKsQueueMutation,
  useAisKsReloadMutation,
  useAisKsDataQuery,
  useAisKsGetTokenMutation,
  useAisKsLinkMutation,
  useAisKsLoadDocumentMutation
} = aisKsApi
