import { CertificateAdjuster } from './certificateAdjuster'
import { cadescomMethods } from './cadescomMethods'
import { doXmlSignatureAlgorithm, doXmlSignatureType } from './xmlSignatureMethods'

import { CADESCOM, CAPICOM } from './constants'


const {
  CAPICOM_CURRENT_USER_STORE,
  CAPICOM_MY_STORE,
  CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
  CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
  CAPICOM_CERTIFICATE_FIND_TIME_VALID,
  CAPICOM_CERTIFICATE_FIND_EXTENDED_PROPERTY,
  CAPICOM_PROPID_KEY_PROV_INFO,
  CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME,
  CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY,
} = CAPICOM

const {
  CADESCOM_ENCODE_BASE64,
  CADESCOM_BASE64_TO_BINARY,
  CADESCOM_CADES_BES,
  CADESCOM_XML_SIGNATURE_TYPE_ENVELOPED,
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411,
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
  CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512,
  CADESCOM_XML_SIGNATURE_TYPE_TEMPLATE
} = CADESCOM

/**
 * @description объект предоставляет методы для получения данных о сертификатах, а так же для их подписания
 */
const CertificatesApi = {}

/**
 * @async
 * @method about
 * @description выводит информацию
 */
CertificatesApi.about = async function about() {
  try {
    return await cadescomMethods.oAbout()
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * @async
 * @method getCertsList
 * @throws {Error}
 * @description получает массив валидных сертификатов
 */
CertificatesApi.getCertsList = async function getCertsList() {
  const oStore = await cadescomMethods.oStore()
  await oStore.Open(
    CAPICOM_CURRENT_USER_STORE,
    CAPICOM_MY_STORE,
    CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
  )

  const certificates = await oStore.Certificates

  if (!certificates) {
    throw new Error('Нет доступных сертификатов')
  }

  const findCertificate = await certificates.Find(CAPICOM_CERTIFICATE_FIND_TIME_VALID)
  const findCertsWithPrivateKey = await findCertificate.Find(
    CAPICOM_CERTIFICATE_FIND_EXTENDED_PROPERTY,
    CAPICOM_PROPID_KEY_PROV_INFO
  )

  const count = await findCertsWithPrivateKey.Count

  if (!count) {
    throw new Error('Нет сертификатов с приватным ключём')
  }

  const createCertList = []

  for (let index = 0; index < count; index++) {
    const certApi = await findCertsWithPrivateKey.Item(index + 1)
    const certificateAdjuster = Object.create(CertificateAdjuster)

    certificateAdjuster.init({
      certApi,
      issuerInfo: await certApi.IssuerName,
      privateKey: await certApi.PrivateKey,
      serialNumber: await certApi.SerialNumber,
      subjectInfo: await certApi.SubjectName,
      thumbprint: await certApi.Thumbprint,
      validPeriod: {
        from: await certApi.ValidFromDate,
        to: await certApi.ValidToDate,
      },
    })

    createCertList.push(certificateAdjuster)
  }

  oStore.Close()

  return createCertList
}

/**
 * @async
 * @method currentCadesCert
 * @param {String} thumbprint значение сертификата
 * @throws {Error}
 * @description получает сертификат по thumbprint значению сертификата
 */
CertificatesApi.currentCadesCert = async function currentCadesCert(thumbprint) {
  if (!thumbprint) {
    throw new Error('Не указано thumbprint значение сертификата')
  } else if (typeof thumbprint !== 'string') {
    throw new Error('Не валидное значение thumbprint сертификата')
  }
  const oStore = await cadescomMethods.oStore()

  await oStore.Open(
    CAPICOM_CURRENT_USER_STORE,
    CAPICOM_MY_STORE,
    CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
  )

  let certificates = await oStore.Certificates

  certificates = await certificates.Find(CAPICOM_CERTIFICATE_FIND_TIME_VALID)
  certificates = await certificates.Find(CAPICOM_CERTIFICATE_FIND_SHA1_HASH, thumbprint)

  const count = await certificates.Count

  if (count) {
    const certificateItem = await certificates.Item(1)
    oStore.Close()
    return certificateItem
  } else {
    oStore.Close()
    throw new Error(
      `Произошла ошибка при получении сертификата,
        на устройстве отсутствуют сертификаты`
    )
  }
}

/**
 * @async
 * @method getCert
 * @param {String} thumbprint значение сертификата
 * @throws {Error}
 * @description
 * Получает сертификат по thumbprint значению сертификата.
 * В отличие от currentCadesCert использует для поиска коллбек функцию getCertsList
 * С помощью этой функции в сертификате доступны методы из certificateAdjuster
 */
CertificatesApi.getCert = async function getCert(thumbprint) {
  if (!thumbprint) {
    throw new Error('Не указано thumbprint значение сертификата')
  } else if (typeof thumbprint !== 'string') {
    throw new Error('Не валидное значение thumbprint сертификата')
  }

  const certList = await this.getCertsList()

  for (let index = 0; index < certList.length; index++) {
    if (thumbprint === certList[index].thumbprint) {
      return await certList[index]
    }
  }

  throw new Error(`Не найдено сертификата по thumbprint значению: ${thumbprint}`)
}

/**
 * @async
 * @method signBase64
 * @param {String} thumbprint значение сертификата
 * @param {String} base64 строка в формате base64
 * @param {Boolean} type тип подписи true=откреплённая false=прикреплённая
 * @throws {Error}
 * @description подпись строки в формате base64
 */
CertificatesApi.signBase64 = async function signBase64(thumbprint, base64, type = true) {
  if (!thumbprint) {
    throw new Error('Не указано thumbprint значение сертификата')
  } else if (typeof thumbprint !== 'string') {
    throw new Error('Не валидное значение thumbprint сертификата')
  }

  const oAttrs = await cadescomMethods.oAtts()
  const oSignedData = await cadescomMethods.oSignedData()
  const oSigner = await cadescomMethods.oSigner()
  const currentCert = await this.currentCadesCert(thumbprint)
  const authenticatedAttributes2 = await oSigner.AuthenticatedAttributes2

  await oAttrs.propset_Name(CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME)
  await oAttrs.propset_Value(new Date())
  await authenticatedAttributes2.Add(oAttrs)
  await oSignedData.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY)
  await oSignedData.propset_Content(base64)
  await oSigner.propset_Certificate(currentCert)
  await oSigner.propset_Options(CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY)

  return await oSignedData.SignCades(oSigner, CADESCOM_CADES_BES, type)
}

/**
 * @async
 * @method signBase64
 * @param {String} thumbprint значение сертификата
 * @param {String} hash хеш строка
 * @throws {Error}
 * @description подпись строки в формате base64
 */
CertificatesApi.signHash = async function signHash(thumbprint, hash) {
  if (!thumbprint) {
    throw new Error('Не указано thumbprint значение сертификата')
  } else if (typeof thumbprint !== 'string') {
    throw new Error('Не валидное значение thumbprint сертификата')
  }

  const oAttrs = await cadescomMethods.oAtts()
  const oSignedData = await cadescomMethods.oSignedData()
  const oSigner = await cadescomMethods.oSigner()
  const currentCert = await this.currentCadesCert(thumbprint)
  const authenticatedAttributes2 = await oSigner.AuthenticatedAttributes2

  const hashAlg = CADESCOM_HASH_ALGORITHM_CP_GOST_3411 // ГОСТ Р 34.11-94

  // Создаем объект CAdESCOM.HashedData
  const oHashedData = await cadescomMethods.oHashedData()

  // Инициализируем объект заранее вычисленным хэш-значением
  // Алгоритм хэширования нужно указать до того, как будет передано хэш-значение
  await oHashedData.propset_Algorithm(hashAlg)
  await oHashedData.SetHashValue(hash)

  await oAttrs.propset_Name(CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME)
  await oAttrs.propset_Value(new Date())
  await authenticatedAttributes2.Add(oAttrs)

  await oSigner.propset_Certificate(currentCert)
  await oSigner.propset_Options(CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY)

  try {
    return await oSignedData.SignHash(oHashedData, oSigner, CADESCOM_CADES_BES)
  } catch (err) {
    throw Error('Не удалось подписать документ: ' + err.message)
  }
}

CertificatesApi.signHashRaw = async function signHashRaw(thumbprint, hash, hashAlg = CADESCOM_HASH_ALGORITHM_CP_GOST_3411) {
  if (!thumbprint) {
    throw new Error('Не указано thumbprint значение сертификата')
  } else if (typeof thumbprint !== 'string') {
    throw new Error('Не валидное значение thumbprint сертификата')
  }

  const oRawSignature = await cadescomMethods.oRawSignature()
  const currentCert = await this.currentCadesCert(thumbprint)

  // Создаем объект CAdESCOM.HashedData
  const oHashedData = await cadescomMethods.oHashedData()

  // Инициализируем объект заранее вычисленным хэш-значением
  // Алгоритм хэширования нужно указать до того, как будет передано хэш-значение
  await oHashedData.propset_Algorithm(hashAlg)
  await oHashedData.SetHashValue(hash)

  try {
    return await oRawSignature.SignHash(oHashedData, currentCert)
  } catch (err) {
    throw Error('Не удалось подписать данные: ' + err.message)
  }
}

/**
 * @async
 * @method signXml
 * @param {String} thumbprint значение сертификата
 * @param {String} xml строка в формате XML
 * @param {Number} cadescomXmlSignatureType тип подписи 0=Вложенная 1=Оборачивающая 2=по шаблону @default 0
 * @description подписание XML документа
 */
CertificatesApi.signXml = async function signXml(
  thumbprint,
  xml,
  cadescomXmlSignatureType = CADESCOM_XML_SIGNATURE_TYPE_TEMPLATE
) {
  try {
    const currentCert = await this.currentCadesCert(thumbprint)
    const publicKey = await currentCert.PublicKey()
    const algorithm = await publicKey.Algorithm
    const value = await algorithm.Value
    const oSigner = await cadescomMethods.oSigner()
    const oSignerXML = await cadescomMethods.oSignedXml()

    const { signAlgorithm, hashAlgorithm } = doXmlSignatureAlgorithm(value)
    const xmlSignatureType = doXmlSignatureType(cadescomXmlSignatureType)

    await oSigner.propset_Certificate(currentCert)
    await oSignerXML.propset_Content(xml)
    await oSignerXML.propset_SignatureType(xmlSignatureType)
    await oSignerXML.propset_SignatureMethod(signAlgorithm)
    await oSignerXML.propset_DigestMethod(hashAlgorithm)

    return await oSignerXML.Sign(oSigner)

  } catch (error) {
    throw new Error(error.message)
  }
}

CertificatesApi.xmlSignatureAlgorithm = async function (thumbprint) {
  const cert = await this.currentCadesCert(thumbprint)
  const publicKey = await cert.PublicKey()
  const algorithm = await publicKey.Algorithm
  const value = await algorithm.Value
  return doXmlSignatureAlgorithm(value)
}

CertificatesApi.b64Cert = async function (thumbprint) {
  const cert = await this.currentCadesCert(thumbprint)
  return await cert.Export(CADESCOM_ENCODE_BASE64)
}

CertificatesApi.hash = async function (data, algorithm = CADESCOM.CADESCOM_HASH_ALGORITHM_CP_GOST_3411){
  const oHashedData = await cadescomMethods.oHashedData()

  oHashedData.propset_Algorithm(algorithm)
  await oHashedData.Hash(data)

  return oHashedData.Value
}

export { CertificatesApi }
