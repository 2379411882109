import { IDocument } from '../../types/document'
import { LightLoadingButton } from '../common/LightButton'
import React, { useMemo, useState } from 'react'
import { useResetMutation } from '../../store/document'
import { toastError } from '../../util/toastError'
import { useGetUserQuery } from '../../store/user'
import { useAuthorization } from '../../auth'
import { useConfirm } from '../../util/useConfirm'

type Props = {
  document: IDocument
  onReset?: () => void
  text?: string
  size?: 'small' | 'medium'
  renderButton?: (onClick: () => void) => React.ReactElement
}

export const ResetButton = ({document, size = 'medium', text = 'Сбросить', onReset, renderButton}: Props) => {

  const [reset] = useResetMutation()
  const [isLoading, setIsLoading] = useState(false)
  const confirm  = useConfirm()


  const { data: user} = useGetUserQuery()
  const authManager = useAuthorization()
  const isOwner = useMemo(() => {
    return user ? authManager.vote(`Document.${document.type}`, 'manage', user.id === document.owner_id) : false
  }, [document, user, authManager])

  const isAllowed = (['sign'].includes(document.status) && isOwner) || (user && user.role === 'admin')


  const handleReset = () => {

    confirm({
      title: 'Сбросить документ?',
      content: 'Документ вернется к состоянию черновика, вся история согласований/подписаний будет удалена.'
    }).then(() => {

      setIsLoading(true)
      reset({
        buildingId: document.building_id,
        body: {
          document_id: document.id,
        },
      })
        .unwrap()
        .then((res) => {
          onReset && onReset()
        })
        .catch(toastError)
        .finally(() => setIsLoading(false))
    })
  }

  if (!isAllowed) return null

  return (
    <>
      {renderButton && renderButton(handleReset)}
      {!renderButton &&
        <LightLoadingButton onClick={handleReset} size={size} loading={isLoading}>
          <span>{text}</span>
        </LightLoadingButton>
      }
    </>
  )
}
