import { IDocument } from '../../../types/document'
import { Box, Card, CardContent, CardHeader, Divider, useMediaQuery, useTheme } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { htmlUrl } from '../../../util/uuid/htmlUrl'
import { ActDownloadButton } from './ActDownloadButton'
import { StatusChip } from '../../common/StatusChip'

type Props = {
  document: IDocument
}
export const ActOSRViewHtml = ({document}: Props) => {

  const [iframeSrc, setIframeSrc] = useState('')

  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"))
  const isLg = useMediaQuery(theme.breakpoints.up("lg"))

  useEffect(() => {
    if (['draft', 'rework'].includes(document.status)) return;
    htmlUrl(document.building_id, document.id, "AOSR")
      .then(src => {
        if (src) {
          setIframeSrc(src)
        }
      })
  }, [])

  const width = (isLg ? 800 : isMd ? 600 : '100%')

  return(
    <>
      {iframeSrc &&
        <Box sx={{alignSelf: 'stretch'}}>
          <Card sx={{minWidth: width, height: '100%', display: 'flex', flexDirection: 'column'}}>
            <CardHeader
              title={`${document.data.name} ${document.number}`}
              action={<StatusChip status={document.status} />}
            />
            <Divider />
            <CardContent sx={{flexGrow: 1, minHeight: 300}}>
              <iframe src={iframeSrc} style={{border: 0, height: '100%', minHeight: 300, width: '100%'}}></iframe>
            </CardContent>
            <CardContent>
              <ActDownloadButton document={document}/>
            </CardContent>
          </Card>
        </Box>
      }
    </>
  )
}
