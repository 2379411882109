import { TExternalDocument } from '../../types/external-document'
import { Box, Card, CardContent, Divider, Stack } from '@mui/material'
import * as React from 'react'
import ExtDocView from './ExtDocView'
import ExtDocFile from './ExtDocFile'
import ExtDocSignatures from './ExtDocSignatures'

type Props = {
  document?: TExternalDocument
}
const DocumentView = ({ document }: Props) => {
  if (!document) {
    return <></>
  }

  return (
    <Stack spacing={2}>
      <Card>
        <CardContent>
          <ExtDocView document={document} />
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <ExtDocFile document={document} />
        </CardContent>
      </Card>

      {document.file?.signatures && document.file.signatures.length > 0 &&
      <Card>
        <CardContent>
          <ExtDocSignatures document={document} />
        </CardContent>
      </Card>
      }
    </Stack>
  )
}

export default DocumentView
