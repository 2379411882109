import { documentType, IDocument } from '../../../types/document'
import { useGetDocumentsQuery, useRemoveOverMutation } from '../../../store/document'
import ParseError from '../../../api/parseError'
import { toast } from 'react-toastify'
import { useMemo } from 'react'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import { StyledTableRow } from '../../journals/gwj/part3/StyledTableRow'
import { formatDate } from '../../../util/date'
import { Delete } from '@mui/icons-material'
import * as React from 'react'
import { useGetStoreQuery } from '../../../store/store'
import { useGetMaterialsQuery, useGetUnitsQuery } from '../../../store/lists'
import { IStore } from '../../../types/store'
import AddActOSRImcDialog from './AddActOSRImcDialog'
import { useGetExternalDocumentsQuery } from '../../../store/externalDocuments'
import ExtDocLine from '../../ExternalDocuments/ExtDocLine'

type Props = {
  document: IDocument
  onChange: () => void
  isManage: boolean
}
const ActOSRMaterials = ({ document, onChange, isManage }: Props) => {
  const { data: imcData } =
    useGetDocumentsQuery({ buildingId: document.building_id, params: {type: documentType.IMCItem} })

  const { data: extDocs } = useGetExternalDocumentsQuery({ buildingId: document.building_id })

  const { data: storesRes } = useGetStoreQuery(document.building_id)

  const {data: materials} = useGetMaterialsQuery(document.building_id)

  const { data: units } = useGetUnitsQuery(document.building_id)

  const stores = useMemo((): Record<string, IStore> => {
    if (storesRes === undefined) return {}
    return storesRes.reduce((acc, store) => ({ ...acc, [store.imc_id]: store }), {})
  }, [storesRes])


  const [removeImc] = useRemoveOverMutation()

  const removeItemHandler = (docId: string) => {
    if (window.confirm('Удалить документ из записи?')) {
      removeImc({
        buildingId: document.building_id,
        body: {
          document_id: document.id,
          document_over_id: docId,
        },
      })
        .unwrap()
        .then((res) => {
          onChange()
        })
        .catch(async (e) => {
          const error = await ParseError(e)
          toast.error(error)
        })
    }
  }


  return (
    <Card variant={'elevation'} elevation={0} sx={{ mb: 2 }}>
      <CardHeader
        titleTypographyProps={{ variant: 'body1', sx: { fontWeight: 'medium' } }}
        title = 'Материалы, примененные при выполнении работ'
      />
      <CardContent sx={{ p: 0 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              {isManage && (
                <TableCell align="right">
                  <AddActOSRImcDialog document={document} onAdd={console.log} />
                </TableCell>
              )}
            </TableRow>
          </TableBody>
          <TableBody>
            {document.over[documentType.IMCItem]?.map((over) => {
              const item = imcData?.items.find(i => i.id === over.over_id)
              if (!item) return null
              const store = stores[item.id]
              return (
                <StyledTableRow key={item.id}>
                  <TableCell sx={{ whiteSpace: 'pre-wrap', width: '100%' }}>
                    {materials && store && materials[store.material_id].name}
                    <Typography variant="subtitle2" color="text.disabled">
                      {formatDate(item.date)}
                    </Typography>
                    {extDocs && <Typography>
                      {item.external_documents.dpk?.map(ed => {
                        const doc = extDocs.items.find((doc) => doc.id === ed.external_document_id)
                        return(
                          <span key={ed.external_document_id}>
                            {doc && <Typography variant={'subtitle2'}>
                              <ExtDocLine document={doc} />
                            </Typography>}
                          </span>
                        )
                      })}
                    </Typography>}
                  </TableCell>
                  {isManage && (
                    <TableCell align="right">
                      <IconButton
                        color="error"
                        title="Удалить"
                        onClick={() => removeItemHandler(over.id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  )}
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
export { ActOSRMaterials }
