import { Button, ButtonProps } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as React from 'react'
import { LightButton } from './LightButton'


export const DownloadButton = ({children, variant, color, startIcon, ...props}: ButtonProps) => {

  return(
    <LightButton
      variant={variant || 'outlined'}
      color={color || 'info'}
      startIcon={startIcon || <FileDownloadIcon />}
      {...props}
    >{children || 'Скачать'}</LightButton>
  )
}
