import { toast } from 'react-toastify'
import { useAddBuildingMutation } from '../../../store/buildings'
import React, { useState } from 'react'
import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import { FormDialog } from '../../common/FormDialog'
import { BuildingForm } from './BuildingForm'
import { toastError } from '../../../util/toastError'

export const AddBuildingDialog = () => {
  const id = 'add-building-form'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [addBuilding] = useAddBuildingMutation()

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)
    return addBuilding(values)
      .unwrap()
      .then((res: any) => {
        toast.success('Объект успешно сохранен')
        setOpen(false)
        methods.reset()
      })
      .catch(toastError)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <Button variant="contained" startIcon={<Add />} onClick={() => setOpen(true)}>
        добавить объект
      </Button>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить объект'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <BuildingForm onSubmit={handleSubmit} id={id} />
      </FormDialog>
    </>
  )
}
