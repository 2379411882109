import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { ISetting } from '../../types/settings'

type TSettingData = {
  name: string
  value: string
  crypt?: boolean
}

type TSetRequest = {
  user_id?: string
  building_id?: string
  level: 'building' | 'user'
  data: TSettingData[]
}

export const settingsApi = createApi({
  reducerPath: 'settings',
  tagTypes: ['Settings'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    getSettings: builder.query<ISetting[], any>({
      query: (params) => ({
        url: `/settings/get`,
        method: 'GET',
        params: params
      }),
      providesTags: ['Settings'],
    }),
    setSettings: builder.mutation<any, TSetRequest>({
      query: (body) => ({
        url: `/settings/set`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Settings'],
    }),
  }),
})

export const {
  useGetSettingsQuery,
  useSetSettingsMutation
} = settingsApi
