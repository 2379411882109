import { TBuilding } from '../../../../types/building'
import { Card, CardContent, CardHeader, IconButton, Table, TableCell, TableRow } from '@mui/material'
import { useGetXmlArchivesQuery, useRemoveXmlArchiveMutation } from '../../../../store/xml'
import { formatDateTime } from '../../../../util/date'
import { IXmlDocument } from '../../../../types/xml'
import React, { useMemo, useState } from 'react'
import { XmlArchivesDialog } from './XmlArchivesDialog'
import { RightPanel } from '../../../common'
import { XmlArchiveView } from './XmlArchiveView'
import { toastError } from '../../../../util/toastError'
import { LightButton } from '../../../common/LightButton'
import DeleteIcon from '@mui/icons-material/Delete';


type Props = {
  building: TBuilding
  type: string
  documents: IXmlDocument[]
}
export const XmlArchives = ({building, type, documents}: Props) => {

  const [currentId, setCurrentId] = useState<string | undefined>()
  const [drawerOpen, setDrawerOpen] = useState(false)

  const {data: archives, refetch} = useGetXmlArchivesQuery({buildingId: building.id, params: {type: 'gwj'}})
  const [remove] = useRemoveXmlArchiveMutation()

  const currentItem = useMemo(() => {
    return archives && currentId && archives.find((item) => item.id === currentId)
  }, [archives, currentId])

  const viewItem = (id: string) => {
    setCurrentId(id)
    setDrawerOpen(true)
  }

  const handleRemove = (id: string) => {
    remove({
      buildingId: building.id,
      body: {
        archive_id: id
      }
    }).unwrap()
      .catch(toastError)
  }

  return(
    <>
      <Card>
        <CardHeader
          title={'Архивы'}
          subheader={'Комплекты документов'}
          action={<XmlArchivesDialog building={building} type={type} documents={documents} />}
        />
        <Table>
          {archives && archives.map(a => (
            <TableRow key={a.id} hover onClick={() => viewItem(a.id)} sx={{cursor: 'pointer'}}>
              <TableCell>{formatDateTime(a.date)}</TableCell>
              <TableCell>{'документов: '}{a.documents.length}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleRemove(a.id)} color={'error'}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Card>
      {currentId && currentItem && <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        actions={
          <LightButton color={'error'} onClick={() => handleRemove(currentId)}>Удалить</LightButton>
        }
      >
        {currentItem && (
          <XmlArchiveView archive={currentItem} onChange={refetch} />
        )}
      </RightPanel>}
    </>
  )
}
