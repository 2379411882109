import { createBrowserRouter } from 'react-router-dom'
import Layout from '../layout/layot/Layout'
import Home from '../pages/home'
import { Documents } from '../pages/documents/Documents'
import { Profile } from '../pages/profile/Profile'
import { Building } from '../pages/building/Main/Building'
import { Company } from '../pages/building/Company/Company'
import { BuildingData } from '../pages/building/Main/BuildingData'
import Join from '../pages/join/Join'
import { Login } from '../pages/login/Login'
import Confirm from '../pages/join/confirm'
import Success from '../pages/join/success'
import { Reset } from '../pages/reset/Reset'
import { Request } from '../pages/reset/Request'
import OAuth from '../pages/oauth'
import { ErrorBoundary } from './ErrorBoundary'
import { CompanyMain } from '../pages/building/Company/CompanyMain'
import { ExternalDocuments } from '../pages/building/ExternalDocuments/ExternalDocuments'
import { ExternalDocumentsMain } from '../pages/building/ExternalDocuments/ExternalDocumentsMain'
import { Journals } from '../pages/building/Journals/Journals'
import { JournalsMain } from '../pages/building/Journals/JournalsMain'
import { Works } from '../pages/building/Works/Works'
import { WorksMain } from '../pages/building/Works/WorksMain'
import { Gwj } from '../pages/building/Journals/Gwj/Gwj'
import { Part1 } from '../pages/building/Journals/Gwj/Part1'
import { Part2 } from '../pages/building/Journals/Gwj/Part2'
import { TitleList } from '../pages/building/Journals/Gwj/TitleList'
import { ChangeList } from '../pages/building/Journals/Gwj/ChangeList'
import { Part3 } from '../pages/building/Journals/Gwj/Part3'
import { Part4 } from '../pages/building/Journals/Gwj/Part4'
import { Part5 } from '../pages/building/Journals/Gwj/Part5'
import { Part6 } from '../pages/building/Journals/Gwj/Part6'
import { Part7 } from '../pages/building/Journals/Gwj/Part7'
import { ImcTitleList } from '../pages/building/Journals/Imc/ImcTitleList'
import { ImcList } from '../pages/building/Journals/Imc/ImcList'
import { Imc } from '../pages/building/Journals/Imc/Imc'
import { Employee } from '../pages/building/Company/Employee/Employee'
import { EmployeeMain } from '../pages/building/Company/Employee/EmployeeMain'
import { Pd } from '../pages/building/Prd/Pd'
import { Rd } from '../pages/building/Prd/Rd'
import { BuildingCard } from '../pages/building/Main/BuildingCard'
import { Companies } from '../pages/building/Main/Companies'
import { CompaniesMain } from '../pages/building/Company/CompaniesMain'
import InvaiteConfirm from '../pages/invite/confirm/InviteConfirm'
import { BuildingUsers } from '../components/building/users/BuildingUsers'
import React from 'react'
import { Xml } from '../pages/building/Journals/Gwj/Xml'
import { ImcXml } from '../pages/building/Journals/Imc/ImcXml'
import { Acts } from '../pages/building/acts/acts'
import { ActsMain } from '../pages/building/acts/ActsMain'
import { ActsOSR } from '../pages/building/acts/ActsOSR'
import * as Sentry from "@sentry/react";
import { AisKs } from '../pages/building/aisks/AisKs'
import { AisKsMain } from '../pages/building/aisks/AisKsMain'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
);

export const privateRouter = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      { index: true, element: <Home /> },
      { path: '/documents', element: <Documents /> },
      { path: '/profile', element: <Profile /> },
      {
        path: '/building/:buildingId',
        element: <Building />,
        handle: { crumb: 'Объект', path: 'data' },
        children: [
          {
            path: 'data',
            element: <BuildingData />,
            handle: { key: 'data' },
            children: [
              { index: true, element: <BuildingCard />, handle: { key: 'card' } },
              {
                path: 'companies',
                element: <CompaniesMain />,
                handle: { crumb: 'Компании', key: 'companies' },
                children: [
                  { index: true, element: <Companies /> },
                  {
                    path: 'company/:companyId',
                    element: <Company />,
                    handle: { crumb: 'Компания' },
                    children: [
                      { index: true, element: <CompanyMain /> },
                      {
                        path: 'employee/:employeeId',
                        element: <Employee />,
                        handle: { crumb: 'Сотрудник' },
                        children: [{ index: true, element: <EmployeeMain /> }],
                      },
                    ],
                  },
                ],
              },
              {
                path: 'pd',
                element: <Pd />,
                handle: { crumb: 'Проектная документация', key: 'pd' },
              },
              {
                path: 'rd',
                element: <Rd />,
                handle: { crumb: 'Рабочая документация', key: 'rd' },
              },
              {
                path: 'users',
                element: <BuildingUsers />,
                handle: { crumb: 'Пользователи', key: 'users' },
              },
            ],
          },
          {
            path: 'ext-docs',
            element: <ExternalDocuments />,
            handle: { crumb: 'Внешние документы' },
            children: [{ index: true, element: <ExternalDocumentsMain /> }],
          },
          {
            path: 'works',
            element: <Works />,
            handle: { crumb: 'Работы' },
            children: [{ index: true, element: <WorksMain /> }],
          },
          {
            path: 'journals',
            element: <Journals />,
            handle: { crumb: 'Журналы' },
            children: [
              { index: true, element: <JournalsMain /> },
              {
                path: 'gwj/:journalId',
                element: <Gwj />,
                handle: { crumb: 'Общий журнал работ' },
                children: [
                  {
                    // index: true,
                    path: 'title',
                    element: <TitleList />,
                    handle: { crumb: 'Титульный лист', key: 'title' },
                  },
                  {
                    path: 'change-list',
                    element: <ChangeList />,
                    handle: { crumb: 'Лист изменений', key: 'change-list' },
                  },
                  {
                    path: 'part1',
                    element: <Part1 />,
                    handle: { crumb: 'Раздел 1', key: 'part1' },
                  },
                  {
                    path: 'part2',
                    element: <Part2 />,
                    handle: { crumb: 'Раздел 2', key: 'part2' },
                  },
                  {
                    // path: 'part3',
                    index: true,
                    element: <Part3 />,
                    handle: { crumb: 'Раздел 3', key: 'part3' },
                  },
                  {
                    path: 'part4',
                    element: <Part4 />,
                    handle: { crumb: 'Раздел 4', key: 'part4' },
                  },
                  {
                    path: 'part5',
                    element: <Part5 />,
                    handle: { crumb: 'Раздел 5', key: 'part5' },
                  },
                  {
                    path: 'part6',
                    element: <Part6 />,
                    handle: { crumb: 'Раздел 6', key: 'part6' },
                  },
                  {
                    path: 'part7',
                    element: <Part7 />,
                    handle: { crumb: 'Раздел 7', key: 'part7' },
                  },
                  {
                    path: 'xml',
                    element: <Xml />,
                    handle: { crumb: 'XML', key: 'xml' },
                  },
                ],
              },

              {
                path: 'imc/:journalId',
                element: <Imc />,
                handle: { crumb: 'Журнал входного контроля' },
                children: [
                  { index: true, element: <ImcTitleList />, handle: { key: 'title' } },
                  { path: 'list', element: <ImcList />, handle: { crumb: 'Записи', key: 'list' } },
                  { path: 'xml', element: <ImcXml />, handle: { crumb: 'XML', key: 'xml' } },
                ],
              },
            ],
          },
          {
            path: 'acts',
            element: <Acts />,
            handle: { crumb: 'Акты' },
            children: [
              { index: true, element: <ActsMain /> },
              {
                path: 'osr',
                element: <ActsOSR />,
                handle: { crumb: 'Акты ОСР' },
              }
            ]
          },
          {
            path: 'aisks',
            element: <AisKs />,
            handle: { crumb: 'АИС КС интеграция' },
            children: [{ index: true, element: <AisKsMain /> }],
          },

        ]
      },
    ],
  },

  { path: '/oauth', element: <OAuth /> },
  // ! Last element if no matches
  // { path: "*", element: <NotFound /> }
])

export const publicRouter = createBrowserRouter([
  { path: '/oauth', element: <OAuth /> },
  { path: '/join', element: <Join /> },
  { path: '/invite/confirm', element: <InvaiteConfirm /> },
  { path: '/join/confirm', element: <Confirm /> },
  { path: '/join/success', element: <Success /> },
  { path: '/reset/request', element: <Request /> },
  { path: '/reset/reset', element: <Reset /> },
  { path: '*', element: <Login /> },
])
