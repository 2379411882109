import { useGetUserQuery } from '../../store/user'
import React, { useId, useMemo, useState } from 'react'
import { useGetSettingsQuery, useSetSettingsMutation } from '../../store/settings'
import { toast } from 'react-toastify'
import { toastError } from '../../util/toastError'
import { Card, CardContent } from '@mui/material'
import { LightLoadingButton } from '../common/LightButton'
import { SettingsForm } from './SettingsForm'

export const SettingsCard = () => {

  const id = useId()
  const {data: user} = useGetUserQuery()
  const [isLoading, setIsLoading] = useState(false)
  const {data: settings} = useGetSettingsQuery({})
  const [setSettings] = useSetSettingsMutation()


  const values = useMemo(() => {
    if (!settings || !user) return false
    return settings
      .filter(s => s.user_id === user.id)
      .reduce((acc, s) => ({...acc, [s.name]: s.value}), {})
  }, [settings, user])

  const submitHandler = (values: any) => {

    if (user) {

      setIsLoading(true)

      const data = Object.keys(values).filter(k => values[k] !== '').map(key => ({
        name: key,
        value: values[key],
        crypt: ['aisKsPassword'].includes(key)
      }))

      setSettings({
        user_id: user.id,
        level: 'user',
        data
      }).unwrap()
        .then(() => {
          toast.success('Данные сохранены')
        })
        .catch(toastError)
        .finally(() => setIsLoading(false))
    }

  }


  return(
    <>{values &&
      <Card>
        <CardContent>
          <SettingsForm values={values} id={id} onSubmit={submitHandler} />
        </CardContent>
        <CardContent>
          <LightLoadingButton type={'submit'} form={id} loading={isLoading}>Сохранить</LightLoadingButton>
        </CardContent>
      </Card>
      }
    </>
  )
}
