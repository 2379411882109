import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../../common/FormDialog'
import * as React from 'react'
import { parseError } from '../../../api'
import { documentType, IDocument } from '../../../types/document'
import { useAddOverMutation, useGetDocumentsQuery } from '../../../store/document'
import { LightButton } from '../../common/LightButton'
import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '@mui/material'
import { useGetStoreQuery } from '../../../store/store'
import { useGetMaterialsQuery, useGetUnitsQuery } from '../../../store/lists'
import { IStore } from '../../../types/store'
import { formatDate } from '../../../util/date'
import { FormSelect } from '../../form'


const schema = {
  over_document_id: string().min(1, 'Необходимо заполнить'),
}
const registerSchema = object(schema)

const AddActOSRExtDocDialog = ({
  document,
  onAdd,
}: {
  document: IDocument
  onAdd: () => void
}) => {
  const id = 'add-aver-doc-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [add] = useAddOverMutation()

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema)
  })

  const { handleSubmit } = methods


  const { data: imc } =
    useGetDocumentsQuery({ buildingId: document.building_id, params: {type: documentType.IMCItem} })

  const { data: storesRes } = useGetStoreQuery(document.building_id)

  const {data: materials} = useGetMaterialsQuery(document.building_id)

  const { data: units } = useGetUnitsQuery(document.building_id)

  const stores = useMemo((): Record<string, IStore> => {
    if (storesRes === undefined) return {}
    return storesRes.reduce((acc, store) => ({ ...acc, [store.imc_id]: store }), {})
  }, [storesRes])


  const itemsList = useMemo(() => {
    if (!imc || !stores || !materials || !units) return []
    return imc.items.map(item => ({
      value: item.id,
      label: stores[item.id] ? materials[stores[item.id].material_id].name : '-',
      description: stores[item.id] ? formatDate(item.date) + ` ${stores[item.id].value} ${units[stores[item.id].unit_id].name}` : '-'
    }))
  }, [imc, stores, materials, units])


  const submitHandler = (values: any) => {
    const body = {
      document_id: document.id,
      type: documentType.IMCItem,
      ...values,
    }

    setIsLoading(true)
    add({ buildingId: document.building_id, body })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        setIsLoading(false)
        toast.success('Документ добавлен')
        onAdd && onAdd()
      })
      .catch(async (e) => {
        const error = await parseError(e)
        toast.error('Ошибка: ' + error)
        setIsLoading(false)
      })
  }


  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Добавить
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить документ'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <FormProvider {...methods}>
          <Box
            id={id}
            component="form"
            onSubmit={handleSubmit(submitHandler)}
            noValidate
            autoComplete="off"
          >
            <FormSelect name='over_document_id' label='Запись ЖВК' items={itemsList} />
          </Box>
        </FormProvider>
      </FormDialog>
    </>
  )
}

export default AddActOSRExtDocDialog
