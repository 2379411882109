import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { profileApi } from './profile'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { userApi } from './user'
import { buildingsApi } from './buildings'
import { externalDocumentsApi } from './externalDocuments'
import { worksApi } from './works'
import { listsApi } from './lists'
import { journalsApi } from './journals'
import { gwjApi } from './gwj'
import { documentApi } from './document'
import { storeApi } from './store'
import { prdApi } from './prd'
import { authApi } from './auth'
import { companiesApi } from './companies'
import { employeesApi } from './employees'
import { buildingDocumentsApi } from './buildingDocuments'
import { filesApi } from './files/files'
import { xmlApi } from './xml'
import { aisKsApi } from './aisks'
import { settingsApi } from './settings'
import { esskApi } from './essk'

export const store = configureStore({
  reducer: combineReducers({
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [buildingsApi.reducerPath]: buildingsApi.reducer,
    [externalDocumentsApi.reducerPath]: externalDocumentsApi.reducer,
    [worksApi.reducerPath]: worksApi.reducer,
    [listsApi.reducerPath]: listsApi.reducer,
    [journalsApi.reducerPath]: journalsApi.reducer,
    [gwjApi.reducerPath]: gwjApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [storeApi.reducerPath]: storeApi.reducer,
    [prdApi.reducerPath]: prdApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [employeesApi.reducerPath]: employeesApi.reducer,
    [buildingDocumentsApi.reducerPath]: buildingDocumentsApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [xmlApi.reducerPath]: xmlApi.reducer,
    [aisKsApi.reducerPath]: aisKsApi.reducer,
    [esskApi.reducerPath]: esskApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      userApi.middleware,
      profileApi.middleware,
      buildingsApi.middleware,
      externalDocumentsApi.middleware,
      worksApi.middleware,
      listsApi.middleware,
      journalsApi.middleware,
      gwjApi.middleware,
      documentApi.middleware,
      storeApi.middleware,
      prdApi.middleware,
      companiesApi.middleware,
      employeesApi.middleware,
      buildingDocumentsApi.middleware,
      filesApi.middleware,
      xmlApi.middleware,
      aisKsApi.middleware,
      esskApi.middleware,
      settingsApi.middleware,
    ),
  devTools: true,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
