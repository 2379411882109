import { IDocument } from '../../../types/document'
import { download } from '../../../util/download'
import { DownloadButton } from '../../common/DownloadButton'
import * as React from 'react'

type Props = {
  document: IDocument
}
export const ActDownloadButton = ({document}: Props) => {
  const handleDownload = () => {
    download(`/api/v1/buildings/${document.building_id}/xml/get-file?document_id=${document.id}&type=AOSR`, `${document.id}_AOSR.xml`, 'text/xml')
  }
  return(
    <>
      <DownloadButton onClick={handleDownload} />
    </>

)
}
