import { TBuilding } from '../../../../types/building'
import { LightButton } from '../../../common/LightButton'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { XmlArchivesForm } from './XmlArchivesForm'
import { useId, useState } from 'react'
import { useCreateXmlArchiveMutation, useGetXmlDocumentsQuery } from '../../../../store/xml'
import { documentType } from '../../../../types/document'
import { toast } from 'react-toastify'
import { toastError } from '../../../../util/toastError'
import { IXmlArchive, IXmlDocument } from '../../../../types/xml'

type Props = {
  building: TBuilding
  type: string
  documents: IXmlDocument[]
  onSave?: () => void
}
export const XmlArchivesDialog = ({building, documents, type, onSave}: Props) => {
  const id = useId()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [createArchive] = useCreateXmlArchiveMutation()

  if (!documents) return null

  const handleSubmit = (values: any, methods: any) => {

    setIsLoading(true)
    createArchive({ buildingId: building.id, body: {...values, type} })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        toast.success('Архив сформирован')
        onSave && onSave()
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <LightButton onClick={() => setOpen(true)}>Сформировать архив</LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Сформировать архив'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <XmlArchivesForm
          documents={documents}
          id={id}
          onSubmit={handleSubmit}
        />
      </FormDialog>
    </>
  )

}
