import { IAisKsDocument } from '../../types/aisks'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
} from '@mui/material'
import { DataRow } from '../common/DataRow'
import React from 'react'
import { formatDate } from '../../util/date'
import { LightButton } from '../common/LightButton'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Typography from '@mui/material/Typography'

type Props = {
  document: IAisKsDocument
}
export const DocumentView = ({document}: Props) => {
  return (
    <Stack direction={'column'} spacing={2}>
      <Card>
        <CardContent>
          <DataRow label={'Файл'} text={document.name}></DataRow>
          <DataRow label={'Дата загрузки'} text={formatDate(document.date)}></DataRow>
          <DataRow label={document.internal ? 'Исходящий' : 'Входящий'}
                   text={document.internal ? 'Создан в СКИД' : 'Загружен из АИС КС'}></DataRow>
        </CardContent>
        <CardContent>
          <LightButton startIcon={<FileDownloadOutlinedIcon />}>Скачать</LightButton>
        </CardContent>
      </Card>
      {document.remarks.length > 0 && <Card>
        <CardHeader
          title={'Замечания'}
        />
        <CardContent>
          {document.remarks.map(remark => (
            <Alert
              key={remark.id}
              severity={'warning'}
            >
              <AlertTitle>
                <Typography variant={'caption'}>{formatDate(remark.date)}</Typography>
                <Typography variant={'body1'}>{remark.author}</Typography>
              </AlertTitle>
              <Box>{remark.comment}</Box>
              <Box sx={{mt: 1, justifyContent: 'right', display: 'flex', width: '100%'}}>
                <Button variant={'outlined'} color={'warning'}>Ответить</Button>
              </Box>
            </Alert>
          ))}
        </CardContent>
      </Card>}
    </Stack>
  )
}
