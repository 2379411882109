import { IXmlArchive } from '../../../../types/xml'
import { useEsskCreateJournalMutation } from '../../../../store/essk'
import { useParams } from 'react-router-dom'
import { Card, CardContent, CardHeader } from '@mui/material'
import { LightLoadingButton } from '../../../common/LightButton'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { toastError } from '../../../../util/toastError'

type Props = {
  archive: IXmlArchive
}
export const EsskExport = ({archive}: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [create] = useEsskCreateJournalMutation()
  const { journalId } = useParams()


  const handleCreate = () => {
    setIsLoading(true)
    create({
      buildingId: archive.building_id,
      body: {
        journal_id: journalId,
        archive_id: archive.id
      }
    }).unwrap()
      .then(() => {
        toast.success("Отправлено")
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  return(
    <>
      <Card>
        <CardHeader
          subheader={'ЕССК'}
        />
        <CardContent>
          <LightLoadingButton onClick={handleCreate}>Отправить</LightLoadingButton>
        </CardContent>
      </Card>
    </>
  )
}
