import { IXmlDocument } from '../../../../types/xml'
import { Accordion, AccordionDetails, AccordionSummary, Avatar,
  Box, Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import { DataRow } from '../../../common/DataRow'
import { baseDocumentType } from '../../../../types/document'
import { formatDateTime } from '../../../../util/date'
import React from 'react'
import { download } from '../../../../util/download'
import { FileSignatures } from '../../../file/FileSignatures'
import { FileSignButton } from '../../../file/FileSignButton'
import nl2br from 'react-nl2br'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useAisKsDataQuery } from '../../../../store/aisks'
import { ExportForm } from '../../../aisks/ExportForm'
import { useValidateXmlMutation } from '../../../../store/xml'
import { toastError } from '../../../../util/toastError'
import { useConfirm } from '../../../../util/useConfirm'
import { toast } from 'react-toastify'

type Props = {
  document: IXmlDocument
  onChange: () => void
}

const validateErrors = (errors: string[]) => {
  return(
    <>
      {errors.map((e, i) => (<Box key={i} sx={{mb: 1}}>{e}</Box>))}
    </>
  )
}

export const XmlDocumentView = ({document, onChange}: Props) => {

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [validate] = useValidateXmlMutation()
  const confirm = useConfirm()

  const handleDownload = () => {
    const url = `/api/v1/buildings/${document.building_id}/files/${document.file.id}`
    download(url, document.file.name, document.file.media_type)
  }

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleValidate = () => {
    validate({
      buildingId: document.building_id,
      body: {
        document_id: document.id
      }
    }).unwrap()
      .then((res) => {
        if (res.success) {
          toast.success("Документ успешно прошёл валидацию");
        } else if (res.errors) {
          confirm({
            title: "Ошибки валидации",
            content: validateErrors(res.errors),
            hideCancelButton: true,
            dialogProps: {
              fullWidth: true,
              maxWidth: 'xl'
            },
            confirmationText: 'Закрыть'
          })
        }

      })
      .catch(toastError)
  }

  return (<Stack direction={"column"} spacing={2}>
    <Card>
      <CardContent>
        <DataRow
          label={'Документ'}
          text={baseDocumentType[document.type].name}
          description={baseDocumentType[document.type].description}
        />
        <DataRow
          label={'Сформирован'}
          text={formatDateTime(document.date)}
        />
      </CardContent>
      <CardContent>
        <Stack direction={{md: 'row', xs: 'column'}} spacing={1}>
          <Button variant={'outlined'} color={'success'} onClick={handleDownload}>Скачать</Button>
          <Button variant={'outlined'} color={'success'} onClick={handleValidate}>Валидация</Button>
        </Stack>
      </CardContent>
    </Card>

    <Card>
      <FileSignatures file={document.file} />
      <CardContent>
        <FileSignButton
          file={document.file}
          onSign={onChange}
          text={document.file.signatures && document.file.signatures.length > 0 ? "Переподписать" : "Подписать"}
        />
      </CardContent>
    </Card>

    {document.exports.length > 0 &&
      <Card>
        <CardHeader
          subheader={`Экспорт`}
        />
        {document.exports.map(e => (
        <Accordion key={e.id} expanded={expanded === e.id} onChange={handleChange(e.id)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{alignItems: 'center'}}
          >
            <Box display={'flex'} alignItems={'center'} width={'100%'}>
              <FiberManualRecordIcon fontSize={'small'} color={e.is_success ? 'success' : 'error'} />
              <Typography sx={{ width: '33%', flexShrink: 0, ml: 1 }}>
                {e.system}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                {formatDateTime(e.date)}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant={"body1"}>{'Код:'} {e.status_code}</Typography>
            <Typography variant={"body2"} color={e.is_success ? 'success' : 'error'}>{nl2br(e.message)}</Typography>
          </AccordionDetails>
        </Accordion>
        ))}
      </Card>
    }
    <ExportForm document={document} />
  </Stack>)
}
