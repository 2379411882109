import { useOutletContext } from 'react-router-dom'
import { IOutletBuilding } from '../../../types/outlet-context'
import { BaseContainer } from '../../../components/common/Container'
import React from 'react'
import { Import } from '../../../components/aisks/Import'

export const AisKsMain = () => {
  const { building } = useOutletContext<IOutletBuilding>()

  return (
    <>
      <BaseContainer>
        <Import building={building} />
      </BaseContainer>
    </>
  )
}
