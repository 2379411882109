import { array, boolean, object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '@mui/material'
import FormSelect, { SelectItem } from '../../../form/FormSelect'
import { FormInputSwitch } from '../../../form'
import React from 'react'
import { IXmlDocument } from '../../../../types/xml'
import { baseDocumentType } from '../../../../types/document'
import { formatDateTime } from '../../../../util/date'

const registerSchema = object({
  documents: array(string()),
  is_sign: boolean()
})

type Props = {
  id : string
  onSubmit: (values: any, methods: any) => void
  documents: IXmlDocument[]
}
export const XmlArchivesForm = ({documents, onSubmit, id}: Props) => {

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: {documents: [], is_sign: false}
  })

  const { handleSubmit } = methods

  const docsItems: SelectItem[] = documents.filter(d => d.system === 'skid').map(d => ({
    label: baseDocumentType[d.type].name,
    value: d.id,
    description: formatDateTime(d.date) + ' - ' + baseDocumentType[d.type].description
  }))

  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }


  return(
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormSelect
          name="documents"
          label="Включить документы"
          items={docsItems}
          multiple
        />
        <FormInputSwitch name={'is_sign'} label={'Включить подписи в архив'} />
      </Box>
    </FormProvider>
  )
}
