import { auth } from '../../oauth/Auth'
import { parseError } from '../../api'
import { toastError } from '../toastError'

export const htmlUrl =  async (buildingId: string, documentId: string, type: string) =>  {

  const token: string = await auth.getToken().catch((e) => {
    window.location.reload()
  })

  const url = `/api/v1/buildings/${buildingId}/xml/get-html?document_id=${documentId}&type=${type}`;

  const options: any = {
    method : "GET",
    headers: {
      Authorization: token
    },
  }

  return fetch(url, options)
    .then(async (response) => {
      if (!response.ok) {
        const message = await parseError(response)
        throw new Error(`Не удалось получить файл: ${message}`)
      }
      return response.blob()
    })
    .then(blob => {
      return URL.createObjectURL(blob);
    })
    .catch(toastError)
}
