import * as React from 'react'
import { statusLabel, TDocumentStatus } from '../../types/document'
import { LightChip } from './LightChip'
type TStatus = {
  label: string
  color: 'warning' | 'info' | 'success' | 'error' | 'default' | 'primary' | 'secondary'
}
type TStatuses = Record<TDocumentStatus, TStatus>

const statuses: TStatuses = {
  draft: { label: statusLabel.draft, color: 'warning' },
  rework: { label: statusLabel.rework, color: 'warning' },
  sign: { label: statusLabel.sign, color: 'info' },
  success: { label: statusLabel.success, color: 'success' },
  old: { label: statusLabel.old, color: 'error' },
}

export const StatusChip = ({ status, altText }: { status: TDocumentStatus; altText?: Record<string, string> }) => {
  return (
    <LightChip color={statuses[status].color} label={altText && altText[status] ? altText[status] : statuses[status].label} size="small" />
  )
}
