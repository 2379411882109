import { IDocument } from '../../types/document'
import { MoreVertMenu } from '../menu'
import { ReworkButton } from './ReworkButton'
import { MenuItem } from '@mui/material'
import { ResetButton } from './ResetButton'
import React, { useState } from 'react'
import { useGetUserQuery } from '../../store/user'

type Props = {
  document: IDocument
}
export const SignDocumentActions = ({document}: Props) => {

  const [open, setOpen] = useState(false)
  const { data: user} = useGetUserQuery()

  const isAllow = ['sign'].includes(document.status) || (user && user.role === 'admin');

  if (!isAllow) return null

  return (
    <MoreVertMenu open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
      <ReworkButton document={document} renderButton={(onClick) => (
        <MenuItem onClick={onClick}>На доработку</MenuItem>
      )} />
      <ResetButton document={document} renderButton={(onClick) => (
        <MenuItem onClick={onClick}>Сбросить</MenuItem>
      )} />
    </MoreVertMenu>
  )

}
