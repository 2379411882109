import { IXmlDocument } from '../../types/xml'
import { IAisKsFolder, IAisKsGk } from '../../types/aisks'
import { object, string, TypeOf } from 'zod'
import FormSelect, { SelectItem } from '../form/FormSelect'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Card, CardContent, CardHeader } from '@mui/material'
import { LightLoadingButton } from '../common/LightButton'
import { useAisKsDataQuery, useAisKsLoadDocumentMutation } from '../../store/aisks'
import { toast } from 'react-toastify'
import { toastError } from '../../util/toastError'

const registerSchema = object({
  folder_id: string().min(1, 'Необходимо выбрать раздел'),
  doc_id: string(),
})

const items = (folders: IAisKsFolder[], data: SelectItem[], parent: number|null, level = 0) => {
  folders.filter(f => f.parent_id === parent).forEach(f => {
    data.push({label: "— ".repeat(level) + f.name, value: f.pack_id + '.' + f.id})
    items(folders, data, f.id, level + 1)
  })
}

const extractFolder = (folder?: string) => {
  if (!folder) return {}
  const arr = folder.split('.')
  return {
    pack_id: Number(arr[0]),
    folder_id: Number(arr[1])
  }
}

const createItemFiles = (folder?: IAisKsFolder) =>
  folder && folder.documents.map(d => ({label: d.name, value: d.id + ''}))

type Props = {
  document: IXmlDocument
}
export const ExportForm = ({document}: Props) => {

  const {data: allGk} = useAisKsDataQuery({buildingId: document.building_id})
  const gk = allGk && allGk.find(gk => gk.building_id === document.building_id)

  const [fileItems, setFileItems] = useState<SelectItem[]>()
  const [isLoading, setIsLoading] = useState(false)
  const [load] = useAisKsLoadDocumentMutation()

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema)
  })

  const { handleSubmit, watch, setValue } = methods

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'folder_id' && gk) {
        setFileItems([])
        setValue('doc_id', '')
        if (value[name] !== undefined) {
          const f = extractFolder(value[name])
          const folder = gk.folders.find(folder => folder.id === f.folder_id)
          setFileItems(createItemFiles(folder))
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, gk])

  const submitHandler = (values: any) => {
    if (gk) {
      setIsLoading(true)
      const f = extractFolder(values.folder_id)
      load({
        buildingId: document.building_id,
        body: {
          document_id: document.id,
          doc_id: values.doc_id ? Number(values.doc_id) : null,
          ...f,
          gk_id: gk.id
        }
      }).unwrap()
        .then(() => {
          toast.success('Документ отправлен')
        })
        .catch(toastError)
        .finally(() => setIsLoading(false))
    }
  }

  const folderItems = useMemo(() => {
    if (!gk) return []
    const data: SelectItem[] = []
    items(gk.folders, data, null)
    return data
  }, [gk])

  return(
    <FormProvider {...methods}>
      <Box
        id={'aisks-load-document-form'}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <Card>
          <CardHeader subheader={'АИС КС'} />
          <CardContent>
                <FormSelect
                  name="folder_id"
                  label="Раздел"
                  items={folderItems}
                />
                <FormSelect
                  name="doc_id"
                  label="Файл"
                  hint="В случае обновления уже существующего файла"
                  items={fileItems}
                />
          </CardContent>
          <CardContent>
            <LightLoadingButton type={'submit'} loading={isLoading}>Отправить</LightLoadingButton>
          </CardContent>
        </Card>
      </Box>
    </FormProvider>
  )
}
