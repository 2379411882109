import { IXmlArchive } from '../../../../types/xml'
import {
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  Table,
  TableCell,
  TableRow
} from '@mui/material'
import { DataRow } from '../../../common/DataRow'
import { formatDate, formatDateTime } from '../../../../util/date'
import Typography from '@mui/material/Typography'
import { baseDocumentType } from '../../../../types/document'
import React, { useState } from 'react'
import { LightButton } from '../../../common/LightButton'
import DownloadIcon from '@mui/icons-material/Download';
import { download } from '../../../../util/download'
import { EsskExport } from './EsskExport'
import { FileSignatures } from '../../../file/FileSignatures'
import { FileSignButton } from '../../../file/FileSignButton'

type Props = {
  archive: IXmlArchive
  onChange: () => void
}
export const XmlArchiveView = ({archive, onChange}: Props) => {

  const [openBackdrop, setOpenBackdrop] = useState(false)

  const handleDownload = () => {

    const url = `/api/v1/buildings/${archive.building_id}/files/${archive.file.id}`
    setOpenBackdrop(true)
    download(url, archive.file.name, archive.file.media_type)
      .finally(() => setOpenBackdrop(false))
  }

  return(
    <>
      <Stack direction={'column'} spacing={2}>
        <Card>
          <CardContent>
            <DataRow label={'Дата формирования'} text={formatDateTime(archive.date)} />
          </CardContent>
          <CardContent>
            <LightButton onClick={handleDownload} startIcon={<DownloadIcon />}>Скачать</LightButton>
          </CardContent>
        </Card>
        <Card>
          <FileSignatures file={archive.file} />
          <CardContent>
            <FileSignButton
              file={archive.file}
              onSign={onChange}
              text={archive.file.signatures && archive.file.signatures.length > 0 ? "Переподписать" : "Подписать"}
            />
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            subheader={'Состав комплекта'}
          />
          <Table>
            {archive.documents.map(d => (
              <TableRow key={d.id}>
                <TableCell>
                  <Typography variant={'body1'}>{baseDocumentType[d.type].name}</Typography>
                  <Typography variant={'caption'}>{baseDocumentType[d.type].description}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Card>
        <EsskExport archive={archive} />
      </Stack>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
