import { TBuilding } from '../../../../types/building'
import {
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText, Stack
} from '@mui/material'
import { baseDocumentType, TBaseDocumentType } from '../../../../types/document'
import { useParams } from 'react-router-dom'
import React, { useMemo, useState } from 'react'
import { useGetXmlDocumentsQuery } from '../../../../store/xml'
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import { XmlDocumentDialog } from '../../../common/XmlDocumentDialog'
import { RightPanel } from '../../../common'
import { useAuthorization } from '../../../../auth'
import { XmlDocumentView } from './XmlDocumentView'
import { formatDateTime } from '../../../../util/date'
import { toast } from 'react-toastify'
import { toastError } from '../../../../util/toastError'
import { useExportMutation } from '../../../../store/journals'
import { IXmlDocument } from '../../../../types/xml'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OpenInNewOffIcon from '@mui/icons-material/OpenInNewOff';
import SignatureIcon from '../../../common/SignatureIcon'
import { XmlArchives } from './XmlArchives'


const DocActions = ({doc}: {doc: IXmlDocument}) => {
  const isSignIcon =
    doc.file.signatures && doc.file.signatures.length > 0 ?
      <Box title={'Подписан'}><SignatureIcon color={'success'} /></Box> :
      <Box title={'Не подписан'}><SignatureIcon color={'disabled'} off={true} /></Box>

  const isExport = doc.exports && doc.exports.length > 0 ?
    <Box title={'Экспорт'}>
      <Badge badgeContent={doc.exports.length + ''} color={'primary'}>
        <OpenInNewIcon />
      </Badge>
    </Box>
    :
    <Box title={'Экспорт отсутсвует'}>
        <OpenInNewOffIcon color={'disabled'} />
    </Box>
  return (<Stack alignItems={'center'} direction={'row'} spacing={2}>{isExport}{isSignIcon}</Stack>)
}

type Props = {
  building: TBuilding
}
const XmlList = ({building}: Props) => {

  const { journalId } = useParams()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>()

  const {data: documents, refetch} = useGetXmlDocumentsQuery(building.id)
  const [exportFiles] = useExportMutation();


  const currentItem = useMemo(() => {
    return documents && documents.find((item) => item.id === currentId)
  }, [documents, currentId])

  const authManager = useAuthorization()
  const isManage = authManager.vote('Gwj', 'manage')

  const viewItem = (id: string) => {
    setCurrentId(id)
    setDrawerOpen(true)
  }

  const filteredTypes: TBaseDocumentType[] = [
    'GeneralWorkJournal',
    'Participants',
    'BuildingParticipants',
    'WorkInfo',
    'sk5111',
  ]

  const filteredDocuments = documents && documents.filter(d => filteredTypes.includes(d.type))

  const handleExport = () => {
    exportFiles({
      buildingId: building.id,
      body: {
        document_id: journalId
      }
    }).unwrap()
      .then(() => {
        toast.success("Ok")
        refetch()
      })
      .catch(toastError)
  }

  return(
    <Stack direction={'column'} spacing={2}>
      <Card>
        <CardHeader
          title={'Экспорт в XML'}
          subheader={'экспорт в формат Минстрой'}
          action={journalId && isManage && <XmlDocumentDialog building={building} types={filteredTypes} documentId={journalId} />}
        />
        <CardContent>
          <List>
            {filteredDocuments && filteredDocuments.map(d => (
                <ListItem
                  key={d.id}
                  component="div"
                  disablePadding
                  secondaryAction={<DocActions doc={d} />}
                >
                  <ListItemButton onClick={() => viewItem(d.id)}>
                    <ListItemIcon>
                      <IntegrationInstructionsOutlinedIcon
                        color={d.file.signatures && d.file.signatures.length > 0 ? 'success' : 'disabled'}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={baseDocumentType[d.type].name}
                      secondary={`${formatDateTime(d.date)} - ${baseDocumentType[d.type].description}`}
                    />
                  </ListItemButton>
                </ListItem>
              ))
            }
          </List>
        </CardContent>
        <CardContent>
          {/* <span onClick={handleExport}>..</span> */}
        </CardContent>
      </Card>
      <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      >
        {currentItem && (
          <XmlDocumentView document={currentItem} onChange={refetch} />
        )}
      </RightPanel>
      {filteredDocuments && <XmlArchives building={building} documents={filteredDocuments} type={'gwj'} />}
    </Stack>
  )

}

export {XmlList}
