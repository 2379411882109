import { object, string } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import FormInput from '../form/FormInput'
import React from 'react'
import { Box} from '@mui/material'
import Typography from '@mui/material/Typography'
import { zodResolver } from '@hookform/resolvers/zod'

const registerSchema = object({
  esskToken: string(),
  aisKsUsername: string(),
  aisKsPassword: string()
})


type Props = {
  id: string
  values: any
  onSubmit: (values: any) => void
}

export const SettingsForm = ({id, values, onSubmit}: Props) => {

  const methods = useForm({
    resolver: zodResolver(registerSchema),
    defaultValues: values,
  })

  const submitHandler = (values: any) => {
    onSubmit(values)

  }

  return(
    <>
      <FormProvider {...methods}>
        <Box
          component="form"
          id={id}
          onSubmit={methods.handleSubmit(submitHandler)}
          noValidate
          autoComplete="off"
        >
            <Typography variant={'h5'}>Настройки интеграций</Typography>
            <FormInput name="esskToken" label="ЕССК Токен" />
            <FormInput name="aisKsUsername" label="АИС КС логин" />
            <FormInput name="aisKsPassword" type={'password'} label="АИС КС пароль" />
        </Box>
      </FormProvider>
    </>
  )
}
