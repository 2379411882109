import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { IXmlArchive, IXmlDocument } from '../../types/xml'

type TResponse = IXmlDocument[]

type TCreateRequest = {
  buildingId: string
  body: {
    document_id: string
    type: string
  }
}
type TCreateArchiveRequest = {
  buildingId: string
  body: {
    type: string
    documents: string[]
  }
}

export const xmlApi = createApi({
  reducerPath: 'xml',
  tagTypes: ['XmlDocuments', 'XmlArchives'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    getXmlDocuments: builder.query<TResponse, string>({
      query: ( buildingId ) => ({
        url: `/buildings/${buildingId}/xml/documents`,
        method: 'GET',
        // params: params
      }),
      providesTags: (result, error, arg) => [{ type: 'XmlDocuments', id: arg }],
    }),
    getXmlArchives: builder.query<IXmlArchive[], any>({
      query: ( {buildingId, params} ) => ({
        url: `/buildings/${buildingId}/xml/archives`,
        method: 'GET',
        params: params
      }),
      providesTags: (result, error, arg) => [{ type: 'XmlArchives', id: arg.params.type }],
    }),
    createXmlDocument: builder.mutation<void, TCreateRequest>({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/xml/create-document`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'XmlDocuments', id: arg.buildingId }],
    }),
    createXmlArchive: builder.mutation<any, TCreateArchiveRequest>({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/xml/create-archive`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'XmlArchives', id: arg.body.type }],
    }),
    removeXmlArchive: builder.mutation<any, any>({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/xml/remove-archive`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'XmlArchives', id: arg.body.type }],
    }),
    validateXml: builder.mutation<any, any>({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/xml/validate`,
        method: 'POST',
        body,
      }),
    }),
    getHtml: builder.query<any, any>({
      query: ( {buildingId, params} ) => ({
        url: `/buildings/${buildingId}/xml/get-html`,
        method: 'GET',
        params: params
      }),
    }),
  }),
})

export const {
  useGetXmlDocumentsQuery,
  useGetXmlArchivesQuery,
  useCreateXmlDocumentMutation,
  useCreateXmlArchiveMutation,
  useRemoveXmlArchiveMutation,
  useValidateXmlMutation,
  useGetHtmlQuery
} = xmlApi
