import { TBuilding } from '../../types/building'
import { Alert, Box, Card, CardContent, CardHeader, IconButton, Stack, Typography } from '@mui/material'
import {
  useAisKsDataQuery,
  useAisKsGetTokenMutation,
  useAisKsLinkMutation,
  useAisKsQueueMutation, useAisKsReloadMutation
} from '../../store/aisks'
import { LightLoadingButton } from '../common/LightButton'
import React, { useState } from 'react'
import { toastError } from '../../util/toastError'
import { toast } from 'react-toastify'
import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormInput } from '../form'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { IAisKsDocument, IAisKsFolder } from '../../types/aisks'
import { LightChip } from '../common/LightChip'
import { useGetSettingsQuery } from '../../store/settings'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { RightPanel } from '../common'
import { DocumentView } from './DocumentView'
import ReplayIcon from '@mui/icons-material/Replay';


const documents = (folder: IAisKsFolder, onClick: (document: IAisKsDocument) => void) => {
  if (!folder.documents) return null
  return folder.documents.map(document => (
    <TreeItem
      key={document.id}
      itemId={'doc-' + document.id}
      onClick={() => onClick(document)}
      label={(
        <Stack direction={'row'}>
          <InsertDriveFileOutlinedIcon fontSize={'small'} />
          <Typography variant={'body2'}>{document.name}</Typography>
          {document.remarks.length > 0 && <ErrorOutlineOutlinedIcon color={'warning'} fontSize={'small'} />}
        </Stack>
      )}
    />
  ))
}

const foldersByParent = (folders: IAisKsFolder[], parent: number|null, onClick: (document: IAisKsDocument) => void) => {
  const filtered = folders.filter(f => f.parent_id === parent);
  if (!filtered) return null
  return (
    <>
    {filtered.map(folder => (
      <TreeItem key={folder.id} itemId={'folder-' + folder.id} label={folder.name}>
        {foldersByParent(folders, folder.id, onClick)}
        {documents(folder, onClick)}
      </TreeItem>
    ))}
    </>
  )
}


const registerSchema = object({
  username: string().min(1, 'Необходимо заполнить'),
  password: string().min(1, 'Необходимо заполнить'),
})

export const Import = ({building}: {building: TBuilding}) => {

  const [queueRun] = useAisKsQueueMutation()
  const [getToken] = useAisKsGetTokenMutation()
  const [link] = useAisKsLinkMutation()
  const [reload] = useAisKsReloadMutation()
  const [isLoading, setIsLoading] = useState(false)
  const [currentDocument, setCurrentDocument] = useState<IAisKsDocument|undefined>()
  const [drawerOpen, setDrawerOpen] = useState(false)

  const {data} = useAisKsDataQuery({buildingId: building.id})
  const {data: settings, isSuccess: isSettingsLoad, refetch: refetchSettings} = useGetSettingsQuery({building_id: building.id})

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
  })

  const isAccess =
    settings &&
    settings.find(s => s.name === 'aisKsUsername') &&
    settings.find(s => s.name === 'aisKsPassword')

  const {
    handleSubmit,
  } = methods

  const submitHandler = ((values: any) => {
    getToken({
      buildingId: building.id,
      body: values
    }).unwrap()
      .then(() => {
        refetchSettings()
        toast.success('Токен получен')
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  })

  const linkHandler = ((id: number) => {
    link({
      buildingId: building.id,
      body: {
        gk_id: id
      }
    }).unwrap()
      .catch(toastError)
      .finally(() => setIsLoading(false))
  })

  const reloadHandler = ((id: number) => {
    reload({
      buildingId: building.id,
      body: {
        gk_id: id
      }
    }).unwrap()
      .then(res => {
        toast.success('Данные загружены')
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  })


  const getDataHandler = () => {
    setIsLoading(true)
    queueRun({
      buildingId: building.id,
    }).unwrap()
      .then(res => {
        if (res.success) {
          toast.success('Данные получены')
        } else {
          toast.warning('Новых данных нет')
        }
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  const documentClickHandle = (document: IAisKsDocument) => {
    console.log(document)
    setCurrentDocument(document)
    setDrawerOpen(true)
  }

  return(
    <>
      <Stack direction={'column'} spacing={2}>

        {!isAccess &&
          <Alert severity={'warning'}>
            Для работы с сервисом АИС КС необходимо настроить доступ в профиле
          </Alert>
        }

        <Stack direction={'row'}>
          <Typography variant={'h4'}>Загруженные ГК</Typography>
          {isAccess && <LightLoadingButton onClick={getDataHandler} loading={isLoading}>Запросить данные</LightLoadingButton>}
        </Stack>

        {data && data.map(gk => (
          <Card key={gk.id}>
            <CardHeader
              title={gk.name}
              subheader={gk.subject}
              action={isAccess &&
                <IconButton onClick={() => reloadHandler(gk.id)} >
                  <ReplayIcon />
                </IconButton>
              }
            />
            <CardContent>
              <SimpleTreeView>
                {gk.folders && foldersByParent(gk.folders, null, documentClickHandle)}
              </SimpleTreeView>
            </CardContent>
            <CardContent>
              {gk.building_id && gk.building_id === building.id &&
                <LightChip color={'success'} label={'Связан с текущим объектом'} />
              }
              {!gk.building_id &&
                <LightLoadingButton onClick={() => linkHandler(gk.id)}>Связать с текущим объектом</LightLoadingButton>
              }
            </CardContent>
          </Card>
        ))}

      </Stack>
      <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        actions={
          <>
          </>
        }
      >
        {currentDocument && <DocumentView document={currentDocument} />}
      </RightPanel>
    </>
  )
}
