/* eslint-disable import/first */
require('./lib/cadesplugin_api')
import { CertificatesApi } from './certificatesApi'
window.cadesplugin.catch(e => console.log(e))
// window.cadesplugin.set_log_level(4)

const cadespluginOnload = () =>
  (async function cadespluginOnload() {
    try {
      await window.cadesplugin.catch(e => console.log(e))

      const {
        getCertsList,
        getCert,
        currentCadesCert,
        signBase64,
        signHash,
        signHashRaw,
        signXml,
        about,
        xmlSignatureAlgorithm,
        b64Cert,
        hash
      } = CertificatesApi

      return {
        getCertsList,
        getCert,
        currentCadesCert,
        signBase64,
        signHash,
        signHashRaw,
        signXml,
        about,
        xmlSignatureAlgorithm,
        b64Cert,
        hash
      }
    } catch (error) {
      throw new Error(error)
    }
  })()

export { cadespluginOnload }
