import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'


export const esskApi = createApi({
  reducerPath: 'essk',
  tagTypes: ['Essk'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    esskCreateJournal: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/essk/create-journal`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Essk'],
    }),
  }),
})

export const {
  useEsskCreateJournalMutation,
} = esskApi
