import * as React from 'react'
import { Box, Card, CardContent, Stack } from '@mui/material'
import { IDocument } from '../../../types/document'
import { SignDocument } from '../../document/SignDocument'
import { ActOSRViewData } from './ActOSRViewData'
import { ActOSRViewPrd } from './ActOSRViewPrd'
import { ActOSRExtDocs } from './ActOSRExtDocs'
import { ActOSRMaterials } from './ActOSRMaterials'
import { ActOSRViewHtml } from './ActOSRViewHtml'


type Props = {
  document: IDocument
  onReady: () => void
  onSign: () => void
  onChange: () => void
  isManage: boolean
}

export const ActOSRView = ({ document, onSign, onReady, onChange, isManage }: Props) => {

  return (
    <Box>
      <Stack direction={'column'} spacing={2}>
        <Stack direction={{ sm: 'column', md: 'row' }} spacing={2}>
          {!['draft', 'rework'].includes(document.status) &&
            <ActOSRViewHtml document={document} />
          }
          {['draft', 'rework'].includes(document.status) && <Box>
            <ActOSRViewData
              document={document}
              isManage={isManage}
            />
          </Box>}
          <Stack direction={{ md: 'column', xl: 'row' }} spacing={2}>
            <Box>
              <ActOSRViewPrd document={document} isManage={isManage} type={'pd'} meta={'pd'} onChange={console.log} />
              <ActOSRViewPrd document={document} isManage={isManage} type={'rd'} meta={'pd'} onChange={console.log} />
              <ActOSRViewPrd document={document} isManage={isManage} type={'pd'} meta={'accordantPd'} onChange={console.log} />
              <ActOSRViewPrd document={document} isManage={isManage} type={'rd'} meta={'accordantRd'} onChange={console.log} />
            </Box>
            <Box>
              <ActOSRMaterials document={document} isManage={isManage} onChange={console.log} />
              <ActOSRExtDocs document={document} isManage={isManage} type={'isp'} onChange={console.log} />
              <ActOSRExtDocs document={document} isManage={isManage} type={'result'} onChange={console.log} />
            </Box>
          </Stack>
        </Stack>
        <Box>
          <SignDocument document={document} onReady={onReady} onChange={onSign} isAgreement />
        </Box>
      </Stack>
    </Box>
  )
}
